<template>
  <v-card width="310" rounded="lg">
    <v-toolbar flat v-if="!invalidToken">
      <v-toolbar-title class="toolbar-title">
        Criar Nova Senha
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="invalidToken" class="d-flex flex-column justify-center">
      <v-icon class="icon" color="error">fas fa-exclamation-circle</v-icon>

      <p class="text-center mt-5">Link inválido.</p>

      <v-btn
        outlined
        color="primary"
        class="no-text-transform"
        to="/solicitar-nova-senha"
      >
        Solicitar novo link
      </v-btn>
    </v-card-text>

    <v-card-text v-else>
      <v-form>
        <v-text-field
          type="password"
          label="Nova Senha*"
          prepend-icon="fas fa-key"
          :disabled="loadButtonSubmit"
          v-model="data.new_password"
          :error-messages="errors.new_password"
        ></v-text-field>

        <v-text-field
          type="password"
          label="Confirmar Senha*"
          prepend-icon="fas fa-key"
          :disabled="loadButtonSubmit"
          v-model="data.confirm_new_password"
          :error-messages="errors.confirm_new_password"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="!invalidToken">
      <v-btn
        link
        text
        color="primary"
        class="no-text-transform"
        to="/solicitar-nova-senha"
      >
        Solicitar novo link
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="ma-2 white--text no-text-transform"
        :loading="loadButtonSubmit"
        @click="handleSubmitData()"
      >
        Criar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";

export default {
  name: "CreateNewPassword",

  mounted: function () {
    const token = this.$route.params.token;
    if (token === undefined) {
      this.invalidToken = true;
    } else {
      this.data.token = token;
    }
  },

  data: () => ({
    data: {},
    errors: {},
    invalidToken: false,
    loadButtonSubmit: false,
    AuthService: new AuthService("/auth"),
  }),

  methods: {
    async handleSubmitData() {
      try {
        this.errors = {};

        this.loadButtonSubmit = true;

        await this.AuthService.createNewPassword(
          "password/create-new-password",
          this.data
        );

        ToastService("Senha alterada com sucesso!", "success");

        await this.$router.push({ path: "/entrar" });
      } catch (exception) {
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loadButtonSubmit = false;
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  margin-top: 25px;
  color: #0b6775;
  font-weight: bold;
  text-align: center;
  width: 100% !important;
}

.icon {
  font-size: 100px;
}
</style>