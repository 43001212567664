import store from "@/store/index.js";
import ErrorService from "@/services/ErrorService";
import RequestService from "@/services/RequestService";

export default class AuthService extends RequestService {
  constructor(baseEndpoint) {
    super(baseEndpoint);
  }

  me = async (endpoint) => {
    try {
      const { data } = await this.post(endpoint);

      console.log(data)
      await store.dispatch("me", data);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  profile = async (endpoint) => {
    try {
      const { data } = await this.post(endpoint);

      if(data) {
        await store.dispatch("permissions", data);
      }
      
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  login = async (endpoint, params) => {
    try {
      console.log("funcao login endpoint" + endpoint);
      console.log("funcao login params" + params);
      
      const { data } = await this.post(endpoint, params);
      console.log(data)
      await store.dispatch("login", data);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  logout = async (endpoint) => {
    try {
      console.log(endpoint)
      await this.post('logout');

      await store.dispatch("logout");
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  changePassword = async (endpoint, params) => {
    try {
      await this.post(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  requestNewPassword = async (endpoint, params) => {
    try {
      await this.post(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  createNewPassword = async (endpoint, params) => {
    try {
      await this.post(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  resendEmailVerify = async (endpoint, params) => {
    try {
      await this.post(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  emailVerify = async (endpoint) => {
    try {
      await this.get(endpoint);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };
}
